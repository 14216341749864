<template>
  <v-dialog v-model="dialog" persistent max-width="600px" style="z-index: 1">
    <v-card>
      <v-card-title class="d-flex flex-row justify-between app-bold-font">
        Send Coupon
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div style="min-height: 500px; max-height: 61vh; overflow: auto">
        <v-card-text class="app-regular-font">
          Send coupons for volunteer achievements.
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <div style="font-family: 'Poppins-Medium'; color: #1e1e1e">Title</div>
          <v-text-field
            label="title"
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            v-model="coupon.title"
            :rules="[rules.required]"
            dense
          />
        </v-card-text>
        <v-card-text
          class="d-flex py-0"
          :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
        >
          <div>
            <div style="font-family: 'Poppins-Medium'; color: #1e1e1e">
              Goal hours
            </div>
            <div
              :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'"
            >
              <v-text-field
                label="Ex: 120"
                solo
                flat
                outlined
                dense
                class="mt-2 app-regular-font"
                v-model="coupon.goal"
                type="number"
                :rules="[rules.required]"
              />
            </div>
          </div>
          <!-- <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-3'">
            <div class="app-medium-font dark-font-color">
              Date
            </div>
            <div
              class="d-flex mt-2"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <div
                :style="
                  $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'
                "
              >
                <v-menu
                  v-model="date_picker"
                  :close-on-content-click="false"
                  :return-value.sync="awardDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :top="$vuetify.breakpoint.xs"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="award.date"
                      label="MM/DD/YYYY"
                      solo
                      flat
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      autocomplete="off"
                      :style="
                        $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'
                      "
                    />
                  </template>
                  <v-date-picker v-model="awardDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date_picker = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="onSaveDate(awardDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div :style="$vuetify.breakpoint.xs ? 'width: 100%' : ''">
                <vue-timepicker
                  format="hh:mm a"
                  v-model="awardTime"
                  :minute-interval="5"
                  :class="$vuetify.breakpoint.xs ? 'w-100 mb-5' : 'ml-3'"
                />
              </div>
            </div>
          </div> -->
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <div style="font-family: 'Poppins-Medium'; color: #1e1e1e">
            Coupon Code
          </div>
          <div class="d-flex mt-2">
            <div
              :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'"
            >
              <v-text-field
                label="Coupon Code"
                solo
                flat
                outlined
                v-model="coupon.couponCode"
                :rules="[rules.required]"
                style="font-family: 'Poppins-Regular'"
                dense
              />
            </div>
            <!-- Volunteers Dropdown/Select -->
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <div class="app-medium-font dark-font-color">Description</div>
          <v-textarea
            rows="4"
            auto-grow
            label="Description"
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            v-model="coupon.description"
          />
        </v-card-text>
        <v-card-text v-if="message">
          <v-alert dense type="error" v-if="message">{{ message }}</v-alert>
        </v-card-text>
      </div>
      <v-card-actions class="pb-6 mt-3 d-flex flex-row justify-center">
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Generate</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
//import dateFormat from "dateformat";
//import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  components: {
    // VueTimepi0cker
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    couponWinners: {
      type: Object
    },
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      rules: {
        required: value => !!value || "Required."
      },
      coupon: {}
      // date_picker: false,
      // awardDate: null,
      // awardTime: null,
    };
  },
  methods: {
    ...mapActions("coupon", {
      sendCouponDialog: "sendCouponDialog"
    }),
    onChange() {
      this.message = null;
    },
    async onSave() {
      if (!this.coupon.title) {
        this.message = "Please add coupon title.";
        return;
      }
      if (!this.coupon.goal) {
        this.message = "Please add goal hours.";
        return;
      }
      if (!this.coupon.couponCode) {
        this.message = "Please add Coupon Code.";
        return;
      }
      const SaveObj = {
        title: this.coupon.title,
        goalHours: this.coupon.goal,
        coupon_code: this.coupon.couponCode,
        description: this.coupon.description,
        ...this.couponWinners
      };

      try {
        await this.sendCouponDialog(SaveObj);
        this.onClose();
      } catch (error) {
        console.error("Error sending coupon:", error);
        this.message =
          "Failed to send coupon: " +
          (error.response?.data.message || error.message);
      }
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone"
    })
  },
  mounted() {
    this.coupon = { ...this.couponWinners };
  },
  watch: {}
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.time-picker {
  width: 107px;
  border-color: #d094ea !important;
  border-width: 1px;
  height: 40px;
}
.time-picker :deep(input.display-time) {
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker :deep(input.display-time:hover) {
  border-color: currentColor;
}
.time-picker :deep(input.display-time:focus-visible) {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker :deep(.clear-btn) {
  padding: 5px 16px;
  margin-top: 5px;
}
</style>
