var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"width":"100%"},on:{"click":_vm.goToDetails}},[_c('v-card-text',{staticClass:"flex-row mt-3 d-flex",staticStyle:{"width":"100%"}},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/svg/awards.svg'),"width":"30","height":"30","contain":""}})],1),_c('div',{staticClass:"mx-3 d-flex flex-column",staticStyle:{"flex-grow":"1"}},[_c('span',{staticStyle:{"font-size":"16px","font-family":"'Poppins-SemiBold'"}},[_vm._v(" "+_vm._s(_vm.award.title)+" ")]),_c('span',{staticStyle:{"color":"#0a5b8a","font-size":"12px","font-family":"'Poppins-Regular'"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.award.offer_date))+" ")]),_c('span',[_vm._v(" Goal: "+_vm._s(_vm.award.goal)+" hrs ")]),(_vm.profileType != 'Volunteer')?_c('span',[_vm._v(" Coupon Code: "+_vm._s(_vm.award.couponCode)+" ")]):_vm._e(),(_vm.profileType != 'Volunteer')?_c('span',[_vm._v(" Coupon Counts: "+_vm._s(_vm.award.couponCounts)+" ")]):_vm._e(),_c('div',[_vm._v(" Items: "),_vm._l((_vm.award.items),function(item){return _c('span',{key:item.name,staticStyle:{"font-size":"12px","font-family":"'Poppins-Regular'"}},[_vm._v(" "+_vm._s(item.name)+_vm._s(item.unit ? `(${item.unit}),` : "")+" ")])})],2),(_vm.award.sponsor_name)?_c('div',[_vm._v(" Sponsor: "),_c('span',{class:{ 'url-button': _vm.award.sponsor_type != 'Admin' },on:{"click":function($event){$event.stopPropagation();return _vm.goToAgencyProfile(
              _vm.award.sponsor_type == 'Admin' ? '' : _vm.award.sponsor
            )},"mousedown":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.award.sponsor_name))])]):_vm._e()]),(
        _vm.award.reedemed.some(
          e => e.user_id && e.user_id.toString() === _vm.profile._id && e.viewed
        )
      )?_c('div',{staticClass:"code-title"},[_vm._v(" Coupon Code Revealed! ")]):_vm._e(),(
        _vm.award.reedemed.some(
          e => e.user_id && e.user_id.toString() === _vm.profile._id && e.viewed
        )
      )?_c('div',{staticClass:"code",on:{"click":_vm.copyCouponCode}},[_vm._v(" "+_vm._s(_vm.award.couponCode)+" ")]):_vm._e(),(!_vm.hideButtons)?_c('div',{staticStyle:{"flex-shrink":"0"}},[(_vm.profile._id == _vm.award.sponsor)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border-radius":"36px","border-color":"#0a5b8a","border-style":"solid"},attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"margin-top":"2px"},attrs:{"color":"#0a5b8a","size":"18"}},[_vm._v("mdi-pencil")])],1)]}}],null,false,866481706)},[_vm._v(" Edit Award ")]):_vm._e(),(_vm.profile._id == _vm.award.sponsor)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",staticStyle:{"border-radius":"36px","border-color":"#0a5b8a","border-style":"solid"},attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"margin-top":"2px"},attrs:{"color":"#0a5b8a","size":"18"}},[_vm._v("mdi-delete")])],1)]}}],null,false,121869813)},[_vm._v(" Delete Award ")]):_vm._e(),(
          _vm.profile._id == _vm.award.sponsor &&
            _vm.users.length > 0 &&
            _vm.profileType != 'Volunteer'
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",staticStyle:{"border-radius":"36px","border-color":"#0a5b8a","border-style":"solid"},attrs:{"icon":"","small":""},on:{"click":function($event){_vm.sendCoupon(
                _vm.award._id,
                _vm.users.map(user => user._id)
              )}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"margin-top":"2px"},attrs:{"color":"#0a5b8a","size":"18"}},[_vm._v("mdi-seal ")])],1)]}}],null,false,883063339)},[_vm._v(" Send Coupon ")]):_vm._e()],1):_vm._e()]),_c('v-card-text',{staticClass:"pt-0 mt-0"},[_c('div',{staticClass:"position-relative"},[(_vm.text_collapsed)?_c('span',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"text-block",domProps:{"textContent":_vm._s(_vm.collapsed_text)}}):_c('span',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"text-block",domProps:{"textContent":_vm._s(_vm.award.description)}}),(_vm.text_collapsed)?_c('span',{staticClass:"url-button",staticStyle:{"position":"absolute","right":"18px","bottom":"0","background":"white"},attrs:{"x-small":""},on:{"click":function($event){$event.stopPropagation();_vm.text_collapsed = false},"mousedown":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();}}},[_vm._v(" ...see more ")]):_vm._e()]),(_vm.users.length > 0 && _vm.profileType != 'Volunteer')?_c('div',{staticClass:"mt-2 app-bold-font"},[_vm._v(" Eligible users "),_vm._l((_vm.users),function(user){return _c('div',{key:user._id,staticClass:"my-4"},[_c('editable-avatar',{staticClass:"ml-2",attrs:{"image":user.midThumbnail ? user.midThumbnail : user.thumbnail,"editable":false,"size":30}}),_c('span',{staticClass:"ml-2",staticStyle:{"font-family":"'Poppins-SemiBold'","color":"#242f36"}},[_vm._v(" "+_vm._s(user.first_name)+" "+_vm._s(user.last_name)+" - "+_vm._s(_vm.formatMinutes(user.volunteered_hours))+" hrs ")])],1)})],2):(_vm.users.length == 0 && _vm.profileType != 'Volunteer')?_c('div',{staticClass:"mt-5"},[_vm._v(" No eligible volunteers ")]):_vm._e(),(_vm.profileType == 'Volunteer')?_c('div',{staticClass:"mt-2"},[_c('span',{staticStyle:{"font-family":"'Poppins-SemiBold'"}},[_vm._v("My hours:")]),_vm._v(" "+_vm._s(_vm.formatMinutes(_vm.myHours))+" hrs ")]):_vm._e(),(
        _vm.myHours >= parseInt(_vm.award.goal) * 60 && _vm.profileType == 'Volunteer'
      )?_c('div',{staticStyle:{"font-family":"'Poppins-SemiBold'"}},[_vm._v(" Eligible ")]):_vm._e(),(!_vm.isCouponRedeemed && _vm.profileType == 'Volunteer')?_c('div',{staticStyle:{"font-family":"'Poppins-SemiBold'","display":"flex","justify-content":"end"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"#084468","width":"150"},on:{"click":function($event){return _vm.couponView(_vm.award)}}},[_vm._v("Reedem")])],1):(_vm.profileType == 'Volunteer')?_c('div',{staticStyle:{"font-family":"'Poppins-SemiBold'"}},[_vm._v(" Not eligible ")]):_vm._e()]),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackMessage)+" ")]),(_vm.editDialog)?_c('edit-awards',{attrs:{"dialog":_vm.editDialog,"onClose":_vm.onCloseDialog,"item":_vm.award}}):_vm._e(),_c('confirm-delete-dialog',{attrs:{"deleteDialog":_vm.deleteDialog,"onClose":_vm.onCloseDialog,"onConfirm":_vm.onDeleteAward}}),_c('couponsend-dialog',{attrs:{"dialog":_vm.sendCouponDialog,"onClose":_vm.onCloseDialog,"couponWinners":_vm.couponWinners}}),_c('coupon-show-dialog',{attrs:{"dialog":_vm.couponShowDialog,"onClose":_vm.onCloseDialog,"showCouponcodes":_vm.showCouponcodes},on:{"coupon-revealed":_vm.handleCouponRevealed}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }